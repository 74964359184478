import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { MustMatch } from 'src/app/shared/helpers/must-match.validator';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CoreService } from 'src/app/shared/services/core.service';
import { TitleService } from 'src/app/shared/services/title-service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss', '/src/assets/css/common-v2/main-custom.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  email = new UntypedFormControl('', [Validators.required, Validators.email]);
  confirmEmail = new UntypedFormControl('', [Validators.required, Validators.email]);
  forgotPasswordForm: UntypedFormGroup;
  submitted = false;

  constructor(
    private authService: AuthService,
    private coreService: CoreService,
    public router: Router,
    private formBuilder: UntypedFormBuilder,
    private titleService: TitleService,
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Forgot Password');
    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      confirmEmail: ['', [Validators.required, Validators.email]],
    }, {
      validator: MustMatch('email', 'confirmEmail'),
    });
  }

  get forgotPasswordFormControls() {
    return this.forgotPasswordForm.controls;
  }

  async onSubmit() {
    this.submitted = true;
    if (this.forgotPasswordForm.invalid) {
      return;
    }

    const body = {
      email: this.forgotPasswordForm.value.email,
    };

    try {
      const response = await this.authService.forgotPassword(body);
      if (response) {
        this.coreService.showMessageAndRedirect(
          'Success',
          'Password reset e-mail has been sent.',
          'success',
          '/auth/login',
        );
      }
    } catch (error) {
      this.coreService.showMessageAndRedirect(
        'Error',
        error.error.email[0] || 'An Error Occurred, Try Again.',
        'error',
        '/auth/login',
      );
    }

  }

}
